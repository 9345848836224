import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header/header";
import "./WebChatSession.css";
import { message, Upload } from "antd";
import { SendOutlined } from '@ant-design/icons';
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import io from "socket.io-client";
import { DataChatServiceType } from "../contexts/Constant";
import HowItWork from "../shared/HowItWork";
import ChatLayout from "../layout/ChatLayout";

String.prototype.hashUrlToUuid = function() {
  var hash = 0,
    i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

function SessionPage() {
  const user = JSON.parse(localStorage.getItem("user"))
  const searchParams = new URLSearchParams(window.location.search);
  const serviceType = searchParams.get('type');
  console.log("service type", serviceType);
  let userId = Cookies.get("userId");
  if (!userId) {
    userId = uuidv4();
    Cookies.set("userId", userId, { expires: 365 });
    console.log("generate New User ID:", userId);
  }
  var USER_ID = userId;

  var latestSessionId = useRef();
  var inputUrl = useRef();
  const [inputValue, setInputValue] = useState("");

  const host = window.location.host;
  const protocol = window.location.protocol;
  var url = process.env.REACT_APP_API_URL || `${protocol}//${host}`;

  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const inputRef = useRef();  

  // const history = useHistory();
  const navigate = useNavigate();
  const socket_tx = useRef();

  async function checkXFrameOptions(input_url) {
    try {
      const api_url = `${url}/check-url`;
      const response = await fetch(api_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': user.token
        },
        body: JSON.stringify({ url: input_url })
      });
      
      const status = response.status;
      if (status === 200) {
        console.log('X-Frame-Options is not set');
        return true;
      } else if (status === 403) {
        console.log('X-Frame-Options is set');
        alert('This site is inaccessible due to Content-Security-Policy. Please input another URL');
      }
      else if (status === 401) {
        message.error("Unauthorized access. Please login again.");
        navigate("/login");
      }
      else if(response.status == 402) {
        alert("License expired. Please contact support.");
      }
      else if(response.status == 403) {
        alert("Reach the maximum token limit in the license. Please contact support.");
      }
      else if(response.status == 404) {
        alert("Invalid License. Please contact support.");
      }
      else{
        alert(`This site is inaccessible due to an invalid URL: ${input_url}`);
      }
    } catch (error) {
      console.log('Could not check X-Frame-Options due to CORS restrictions');
    }
    return false;
  }

  async function create_chat_session(chat_service_type, source_id, details) {
    try {
      const api_url = `${url}/api/v1/chat-session/create`;
      const response = await fetch(api_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': user.token
        },
        body: JSON.stringify({
          chat_service_type: chat_service_type, 
          source_id: source_id, 
          details: details 
        })
      });
      
      const status = response.status;
      if (status === 200) {
        console.log('create chat session: success');
        const data = await response.json();
        const session_id = data.data.session_id;
        return session_id;
      }
      else if (status === 401) {
        message.error("Unauthorized access. Please login again.");
        navigate("/login");
      }
      else if(response.status == 402) {
        alert("License expired. Please contact support.");
      }
      else if(response.status == 403) {
        alert("Reach the maximum token limit in the license. Please contact support.");
      }
      else if(response.status == 404) {
        alert("Invalid License. Please contact support.");
      }
      else{
        alert(`create chat session: ${status}`);
      }
    } catch (error) {
      console.log('Could not check X-Frame-Options due to CORS restrictions');
    }
    return false;
  }


  async function startNewSession(url) {
    if(await checkXFrameOptions(url) === false) {
      return;
    }

    // latestSessionId.current = USER_ID + '_' + url.hashUrlToUuid();
    console.log("start new session");

    const source_id = USER_ID + '_' + url.hashUrlToUuid() + '_' + uuidv4();

    const session_id = await create_chat_session(DataChatServiceType.WEB_CHAT, source_id, {
      url: url
    });
    if (!session_id) {
      return;
    }
    latestSessionId.current = session_id;
    // latestSessionId = session_id;

    let data = {
      sessionId: latestSessionId.current,
    };
    socket_tx.current.emit("register", JSON.stringify(data));

    data = {
      sessionId: latestSessionId.current,
      file_name: 'YOLOv7.pdf',
      fileType: 0,
      datachat_type: DataChatServiceType.WEB_CHAT,
      datachat_info: {
        url: url,
      },
    };
    socket_tx.current.emit("start_new_session", JSON.stringify(data));

    setIsLoading(true);
  }

  useEffect(() => {
    socket_tx.current = io.connect(url);

    return () => {
      if (socket_tx.current) {
        socket_tx.current.disconnect();
      }
    };
  }, []);


  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      console.log("inputValue", inputValue);
      startNewSession(inputValue);
      inputUrl.current = inputValue
      inputRef.current.focus();
    }
  };

  const handleButtonClick = () => {
    console.log("inputValue", inputValue);
    startNewSession(inputValue);
    inputUrl.current = inputValue
    // handleInput(inputValue);
    // setInputValue("");
    inputRef.current.focus();
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const StreamText = () => {
    console.log("socketio: connected to ", url);

    socket_tx.current.on("connect", function () {
      let data = {
        sessionId: USER_ID,
      };
      socket_tx.current.emit("register", JSON.stringify(data));
    });

    socket_tx.current.on("message", function (markdownString) {
      console.log("message received: ", markdownString);
      console.log("latestSessionId", latestSessionId.current);
      console.log("inputValue", inputUrl.current);
      setIsLoading(false);
      var path_name = `/datachat/session?type=${DataChatServiceType.WEB_CHAT}&id=${latestSessionId.current}&docs=${inputUrl.current}`;
      navigate(path_name);
    });
  };
  useEffect(() => {
    StreamText();
  }, []);

  return (
    <>
      <Header title="header" />
      <ChatLayout
        title="Web Chat"
        description="Input any URL to access, summarize, and interact with web page content effortlessly. Our AI-powered Web Chat tool makes it easy to extract and understand information from any website."
      >
        {isLoading && (
          <div className="ss-modal">
            <span className="ss-loader"></span>Analyzing
          </div>
        )}
        <div className="chat_container">
          <div className="chat_container_main">
            <div className="web_chat_container">
              <div className="web_chat_label">Website link</div>
              <input
                type="text"
                className="web_chat_input"
                placeholder="Input your URL"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                ref={inputRef}
                disabled={isUploading}
              />
              <button className="web_chat_btn" onClick={handleButtonClick}>Go</button>
            </div>
          </div>
          <HowItWork name='Web Chat'/>
        </div>
      </ChatLayout>
    </>
  );
}

export default SessionPage;
