import React, { useEffect, useState } from 'react';
import './ChatHistory.css';
import { Link } from 'react-router-dom';

const ChatHistory = ({ serviceType, onSessionClick }) => {
  const [chatSessions, setChatSessions] = useState([
  ]);

  const host = window.location.host;
  const protocol = window.location.protocol;
  const main_url = process.env.REACT_APP_API_URL || `${protocol}//${host}`;

  useEffect(() => {
    console.log('Fetching chat sessions for service type:', serviceType);
    const fetchChatSessions = async () => {
      try {
        const api_url = `${main_url}/api/v1/chat-session?chat_service_type=${serviceType}`
        console.log('Fetching chat sessions from:', api_url);
        const user = JSON.parse(localStorage.getItem("user"))
        console.log('User:', user);
        const response = await fetch(api_url, {
          method: "GET",
          headers: {
            'token': user.token
          }
        });

        console.log('Response2:', response);
        const body = await response.json();
        console.log('Chat sessions:', body.data);
        setChatSessions(body.data);
      } catch (error) {
        console.error('Error fetching chat sessions:', error);
      }
    };

    fetchChatSessions();
  }, [serviceType]);

  return (
    <div className="chat-history">
      <h2>Chat History</h2>
      <ul>
        {chatSessions.map(session => (
          <li key={session.id} onClick={() => onSessionClick(session.id)}>
          <div className="chat-session">
            <p><strong>{session.name}</strong></p>
            <p>{session.updated_at}</p>
          </div>
        </li>
        ))}
      </ul>
    </div>
  );
};

export default ChatHistory;