import React, { useState } from "react";
import { Upload, Button, Avatar, Typography, Card } from "antd";
import { PaperClipOutlined, CloseOutlined } from "@ant-design/icons";

const { Text } = Typography;

const FileUploadCard = ({ url, user, fileList, setFileList, disabled }) => {
  const handleUploadChange = (info) => {
    if (info.file.status === "done") {
      setFileList([
        ...fileList,
        {
          name: info.file.name,
          type: info.file.type,
          url: info.file.response?.url || "#",
        },
      ]);
    }
  };

  const handleRemoveFile = (index) => {
    const newFileList = fileList.filter((_, i) => i !== index);
    setFileList(newFileList);
  };

  return (
    <div>
      <Upload
        name="file"
        action={`${url}/upload/attachment`}
        headers={{
          token: user.token,
        }}
        onChange={handleUploadChange}
        showUploadList={false}
      >
        <Button
          disabled={disabled}
          icon={<PaperClipOutlined />}
          style={{ borderRadius: "50%", padding: "8px", fontSize: "16px" }}
        />
      </Upload>

      <div style={{ marginTop: "1px" }}>
        {fileList.map((file, index) => (
          <Card
            key={index}
            disabled={disabled}
            style={{
              marginBottom: "1px",
              display: "flex",
              alignItems: "center",
              padding: "1px",
            }}
            bodyStyle={{ padding: 0 }}
          >
            {/* Icon hoặc hình đại diện */}
            {/* <Avatar
              style={{
                backgroundColor: "#ff4d4f",
                marginRight: "10px",
              }}
            >
              {file.type === "application/pdf" ? "PDF" : file.name[0].toUpperCase()}
            </Avatar> */}

          <div
            style={{
              display: "flex", // Sử dụng flexbox
              alignItems: "center", // Đặt các phần tử trên cùng một hàng
              justifyContent: "space-between", // Tạo khoảng cách giữa text và button
              overflow: "hidden", // Đảm bảo xử lý text dài
              flexGrow: 1,
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                maxWidth: "10px", // Giới hạn chiều rộng của text
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {file.name}
            </Text>
            <Button
              type="text"
              icon={<CloseOutlined />}
              danger
              onClick={() => handleRemoveFile(index)}
            />
          </div>



          </Card>
        ))}
      </div>
    </div>
  );
};

export default FileUploadCard;
